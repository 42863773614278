// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_6oE6prcNv6tz6qs3cT", // ht
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/5kAdRJ4JgebQebe00G", // ht

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Hairstyle Transfer AI 🎨",
    HOME_PAGE_TITLE: "Home - Hairstyle Transfer AI 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Hairstyle Transfer AI 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Hairstyle Transfer AI 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to transfer a hairstyle from one image to another, choose two images and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "hairstyle_transfer_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/hairstyle-transfer-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;